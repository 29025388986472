// firebaseConfig.js (New Modular Syntax)
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

const firebaseConfig = {
  apiKey: "AIzaSyBnrYt7bDDzIhwqAX-SBf96hbaQlbVyZYI",
  authDomain: "paylert.firebaseapp.com",
  projectId: "paylert",
  storageBucket: "paylert.firebasestorage.app",
  messagingSenderId: "993931595964",
  appId: "1:993931595964:web:a541c32a100585e57d40b9",
  measurementId: "G-ZNEEK1K97P"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Authentication
const auth = getAuth(app);

export { app, auth };
