// src/CustomMenu.js
import React from 'react';
import { Menu } from 'react-admin';
import DashboardIcon from '@mui/icons-material/Dashboard';
import ListIcon from '@mui/icons-material/List';

const CustomMenu = (props) => (
  <Menu {...props}>
    {/* Existing Items menu item */}
    <Menu.Item to="/items" primaryText="Items" leftIcon={<ListIcon />} />
    {/* New Dashboards menu item */}
    <Menu.Item to="/dashboards" primaryText="Dashboards" leftIcon={<DashboardIcon />} />
  </Menu>
);

export default CustomMenu;
