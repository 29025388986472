// src/CustomLoginPage.js
import React from 'react';
import { useLogin, useNotify, Notification } from 'react-admin';
import { Card, CardContent, TextField, Button, Typography, Box } from '@mui/material';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  main: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100vh',
    backgroundColor: '#f5f5f5',
  },
  card: {
    width: '100%',
    maxWidth: 500,
    padding: '2em',
    margin: '0 1em',
  },
  logo: {
    display: 'block',
    margin: '0 auto 1em auto',
    maxWidth: '100%',
    height: 'auto',
  },
  form: {
    marginTop: '1em',
  },
  input: {
    marginTop: '1em',
  },
  button: {
    width: '100%',
    marginTop: '1.5em',
  },
});

const CustomLoginPage = () => {
  const classes = useStyles();
  const login = useLogin();
  const notify = useNotify();
  const [loading, setLoading] = React.useState(false);
  const [credentials, setCredentials] = React.useState({ email: '', password: '' });

  const submit = (e) => {
    e.preventDefault();
    console.log('Submitting credentials:', credentials);
    setLoading(true);
    login({ username: credentials.email, password: credentials.password })
      .then(() => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        notify(error.message || 'Invalid email or password', { type: 'warning' });
      });
  };

  return (
    <div className={classes.main}>
      <Card className={classes.card}>
        <CardContent>
          {/* Display the logo */}
          <Box
            component="img"
            src="/logo.png" // Ensure this path is correct
            alt="Logo"
            className={classes.logo}
          />
          {/* Welcome message */}
          <Typography variant="h5" align="center" gutterBottom>
            Welcome to Paylert
          </Typography>
          {/* Login Form */}
          <form onSubmit={submit} className={classes.form}>
<TextField
  name="email"
  label="Email"
  fullWidth
  variant="outlined"
  className={classes.input}
  value={credentials.email}
  onChange={(e) => {
    setCredentials({ ...credentials, email: e.target.value });
    console.log('Email input changed:', e.target.value);
  }}
/>


            <TextField
              name="password"
              type="password"
              label="Password"
              fullWidth
              variant="outlined"
              className={classes.input}
              value={credentials.password}
              onChange={(e) => setCredentials({ ...credentials, password: e.target.value })}
            />
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              className={classes.button}
            >
              {loading ? 'Logging in...' : 'Login'}
            </Button>
          </form>
        </CardContent>
      </Card>
      <Notification />
    </div>
  );
};

export default CustomLoginPage;