// dataProvider.js
import { fetchUtils } from 'react-admin';
import simpleRestProvider from 'ra-data-simple-rest';
import { auth } from './firebaseConfig';
import { getIdToken } from 'firebase/auth';

// Use the environment variable
const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:5000';

const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: 'application/json' });
  }

  const user = auth.currentUser;
  if (user) {
    const idToken = await getIdToken(user);
    options.headers.set('Authorization', `Bearer ${idToken}`);
  }

  return fetchUtils.fetchJson(url, options);
};

// Replace 'http://localhost:5000' with your Flask backend URL
const dataProvider = simpleRestProvider(apiUrl, httpClient);

export default dataProvider;
