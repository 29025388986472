// src/App.js
import React, { useState, useEffect } from 'react';
import { Admin, Resource, ListGuesser , CustomRoutes, Layout } from 'react-admin';
import authProvider from './authProvider';
import dataProvider from './dataProvider';
import MetabaseDashboard from './MetabaseDashboard';
import CustomMenu from './CustomMenu';
import { Route } from 'react-router-dom';
import CustomLoginPage from './CustomLoginPage';

function App() {
  const [iframeUrl, setIframeUrl] = useState('');

  useEffect(() => {
    // Use the environment variable
    fetch(`${process.env.REACT_APP_API_URL}/metabaseIframeUrl`)
      .then(response => response.json())
      .then(data => setIframeUrl(data.iframeUrl))
      .catch(error => console.error('Error fetching iframe URL:', error));
  }, []);


  return (
    <Admin
      authProvider={authProvider}
      dataProvider={dataProvider}
      loginPage={CustomLoginPage}
      layout={(props) => <Layout {...props} menu={CustomMenu} />} // Use CustomMenu
    >
      <Resource name="items" />
      <CustomRoutes>
        <Route path="/dashboards" element={<MetabaseDashboard />} />
      </CustomRoutes>
    </Admin>
  );
}

export default App;
