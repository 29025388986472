// src/MetabaseDashboard.js
import React, { useState, useEffect } from 'react';
import { auth } from './firebaseConfig';
import { getIdToken } from 'firebase/auth';

const MetabaseDashboard = () => {
  const [iframeUrl, setIframeUrl] = useState('');

  useEffect(() => {
    async function fetchIframeUrl() {
      try {
        const user = auth.currentUser;
        if (user) {
          const idToken = await getIdToken(user);
          const response = await fetch(`${process.env.REACT_APP_API_URL}/metabaseIframeUrl`, {
            headers: {
              Authorization: `Bearer ${idToken}`,
            },
          });
          if (!response.ok) {
            throw new Error(`HTTP error ${response.status}`);
          }
          const data = await response.json();
          setIframeUrl(data.iframeUrl);
        } else {
          console.error('User not authenticated');
        }
      } catch (error) {
        console.error('Error fetching iframe URL:', error);
      }
    }

    fetchIframeUrl();
  }, []);

  return (
    <div>
      {iframeUrl ? (
        <iframe
          src={iframeUrl}
          frameBorder="0"
          width="100%"
          height="800"
          allowTransparency
          title="Metabase Dashboard"
        ></iframe>
      ) : (
        <p>Loading dashboard...</p>
      )}
    </div>
  );
};

export default MetabaseDashboard;
