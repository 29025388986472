// authProvider.js
import {
  signInWithEmailAndPassword,
  signOut,
  onAuthStateChanged,
  getIdTokenResult,
} from 'firebase/auth';
import { auth } from './firebaseConfig';

const authProvider = {
  login: ({ username, password }) => {
    return signInWithEmailAndPassword(auth, username, password)
      .then(() => Promise.resolve())
      .catch((error) => Promise.reject(error));
  },
  logout: () => {
    return signOut(auth)
      .then(() => Promise.resolve())
      .catch((error) => Promise.reject(error));
  },
  checkAuth: () => {
    return new Promise((resolve, reject) => {
      onAuthStateChanged(auth, (user) => {
        user ? resolve() : reject();
      });
    });
  },
  getPermissions: () => {
    const user = auth.currentUser;
    if (user) {
      return getIdTokenResult(user)
        .then((idTokenResult) => idTokenResult.claims)
        .catch(() => Promise.reject('Failed to get permissions'));
    } else {
      return Promise.resolve({});
    }
  },
  checkError: (error) => Promise.resolve(),
};

export default authProvider;
